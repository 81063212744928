import * as React from "react"

import Layout from "../components/layout"

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

import OtherPageHeader from "../components/otherPageHeader.js";

const NotFoundPage = () => (
  <Layout pageTitle="FAQ">
    <OtherPageHeader />
    <div className="main">
      <div className="section">
        <Container>
          <Row>
            <Col>
              <h1>404: Not Found</h1>
              <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
